import React from "react";
// import { Link } from 'react-router-dom'
const Footer = () => {
  return (
    <>
      <div
        className="container-fluid py-3 pt-5"
        style={{ background: "#1C3C74", width: "100%" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-3 text-center">
              <img
                src="com-logo.jpeg"
                className="mb-4 me-1"
                style={{ width: "100px" }}
              />
              <spna className=" fs-5 fw-bold text-danger">
                K K Financial Services
              </spna>
              <div>
                <p
                  className="text-white mb-4 fs-5"
                  style={{ textAlign: "justify" }}
                >
                  If you are looking for the best loan services in Bengaluru,
                  then you owe it to yourself to get in touch with KK Financial
                  Services. When a large sum of money is required, say for a
                  home or automobile KK finance is the best in bangalore.
                </p>
                <h5 className="text-start text-white">
                  Follows Us :
                  <span
                    className="mx-2"
                    style={{
                      width: "70px",
                      height: "70px",
                      backgroundColor: "#3B5998",
                      padding: "10px 17px",
                      borderRadius: "50%",
                    }}
                  >
                    <a
                      className="text-white"
                      target="_blank"
                      href="https://www.facebook.com/profile.php?id=100067143518218"
                    >
                      <i class="fa fa-facebook"></i>
                    </a>
                  </span>
                  <span
                    className="mx-2"
                    style={{
                      width: "70px",
                      height: "70px",
                      backgroundColor: "#3B77B5",
                      padding: "10px 15px",
                      borderRadius: "50%",
                    }}
                  >
                    <a
                      class="text-white"
                      target="_blank"
                      href="https://www.linkedin.com/in/rajendra-prasad-ginnam-6a390788/"
                    >
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </span>
                  <span
                    className="mx-2"
                    style={{
                      width: "70px",
                      height: "70px",
                      backgroundColor: "#52A1F3",
                      padding: "10px 14px",
                      borderRadius: "50%",
                    }}
                  >
                    <a
                      class="text-white"
                      target="_blank"
                      href="https://twitter.com/home"
                    >
                      <i className="fa fa-twitter"></i>
                    </a>
                  </span>
                </h5>
              </div>
            </div>
            <div className="col-md-3 text-center">
              <h4 className="text-white mb-4 text-start ms-5">
                <span
                  style={{
                    borderBottom: "3px solid #E62E5E",
                    paddingBottom: "15px",
                  }}
                >
                  Pages
                </span>
              </h4>
              <div>
                <ul style={{ listStyle: "none" }}>
                  <li className="py-2">
                    <a href="/" className="nav-link text-white text-start fs-5">
                      <i
                        className="fa fa-check me-2"
                        style={{ color: "#E62E5E" }}
                      ></i>
                      Home{" "}
                    </a>
                  </li>
                  <li className="py-2">
                    <a
                      href="/aboutus"
                      className="nav-link text-white text-start fs-5"
                    >
                      <i
                        className="fa fa-check me-2"
                        style={{ color: "#E62E5E" }}
                      ></i>
                      About Us{" "}
                    </a>
                  </li>

                  <li className="py-2">
                    <a
                      href="services"
                      className="nav-link text-white text-start fs-5"
                    >
                      <i
                        className="fa fa-check me-2"
                        style={{ color: "#E62E5E" }}
                      ></i>
                      Services{" "}
                    </a>
                  </li>
                  <li className="py-2">
                    <a
                      href="contactus"
                      className="nav-link text-white text-start fs-5"
                    >
                      <i
                        className="fa fa-check me-2"
                        style={{ color: "#E62E5E" }}
                      ></i>
                      Contact Us{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-3 ">
              <h4 className="text-white mb-4 text-start ms-5">
                <span
                  style={{
                    borderBottom: "3px solid #E62E5E",
                    paddingBottom: "15px",
                  }}
                >
                  Our Services
                </span>
              </h4>
              <div>
                <ul style={{ listStyle: "none" }}>
                  <li className="py-2">
                    <a
                      href="services"
                      className="nav-link text-white text-start fs-5"
                    >
                      <i
                        className="fa fa-check me-2"
                        style={{ color: "#E62E5E" }}
                      ></i>
                      Home Loan/Mortage Loan
                    </a>
                  </li>
                  <li className="py-2">
                    <a
                      href="services"
                      className="nav-link text-white text-start fs-5"
                    >
                      <i
                        className="fa fa-check me-2"
                        style={{ color: "#E62E5E" }}
                      ></i>
                      Personal Loan
                    </a>
                  </li>
                  <li className="py-2">
                    <a
                      href="services"
                      className="nav-link text-white text-start fs-5"
                    >
                      <i
                        className="fa fa-check me-2"
                        style={{ color: "#E62E5E" }}
                      ></i>
                      Business Loan{" "}
                    </a>
                  </li>
                  <li className="py-2">
                    <a
                      href="services"
                      className="nav-link text-white text-start fs-5"
                    >
                      <i
                        className="fa fa-check me-2"
                        style={{ color: "#E62E5E" }}
                      ></i>
                      Education Loan{" "}
                    </a>
                  </li>
                  <li className="py-2">
                    <a
                      href="services"
                      className="nav-link text-white text-start fs-5"
                    >
                      <i
                        className="fa fa-check me-2"
                        style={{ color: "#E62E5E" }}
                      ></i>
                      Car Loan
                    </a>
                  </li>
                  <li className="py-2">
                    <a
                      href="services"
                      className="nav-link text-white text-start fs-5"
                    >
                      <i
                        className="fa fa-check me-2"
                        style={{ color: "#E62E5E" }}
                      ></i>
                      School infra Funding Loan
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-3 ">
              <h4 className="text-white mb-4 text-start ms-5 mb-5">
                <span
                  style={{
                    borderBottom: "3px solid #E62E5E",
                    paddingBottom: "15px",
                  }}
                >
                  Information
                </span>
              </h4>
              <div className="d-flex ms-2">
                <div>
                  <i
                    class="fa fa-map-marker  me-3"
                    style={{ fontSize: "70px ", color: "#E62E5E" }}
                    aria-hidden="true"
                  ></i>
                </div>
                <div className="text-start">
                  <h5 className="text-white">Address</h5>
                  <p className="text-white fs-5">
                    # 384,3rd Main Road,13th Cross Rd, Sadashiva Nagar,
                    Bengaluru, Karnataka 560080
                  </p>
                </div>
              </div>

              <div className="d-flex ms-2">
                <div>
                  <i
                    class="fa fa-envelope me-3"
                    style={{ fontSize: "50px ", color: "#E62E5E" }}
                    aria-hidden="true"
                  ></i>
                </div>
                <div className="text-start">
                  <h5 className="text-white">Email</h5>
                  <p style={{ color: "#fff" }} className="fs-5">
                    info@kkfinnacialservice.in
                  </p>
                </div>
              </div>

              <div className="d-flex ms-2">
                <div>
                  <i
                    class="fa fa-phone  me-3"
                    style={{ fontSize: "50px ", color: "#E62E5E" }}
                    aria-hidden="true"
                  ></i>
                </div>
                <div className="text-start">
                  <h5 className="text-white">Mobile</h5>
                  <p style={{ color: "#fff" }} className="fs-5">
                    {" "}
                    +91 84949 41209
                  </p>
                </div>
              </div>
            </div>
          </div>

          <hr className="text-white" />
          <div>
            <p className="text-center text-white fs-5">
              Copyright 2023 © All Right Reserved by{" "}
              <a href="#" className="text-white"></a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
