

import React, { useRef, useState, useEffect } from 'react';
import emailjs from 'emailjs-com'; // Updated import statement
import '../cmp/ourtes.css';
export const ContactUs = () => {
  const form = useRef();
  const [message, setMessage] = useState(''); // State for success/error message

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_17u', 'template_q4e8', form.current, 'yt5BJPduWo7s0GFI5')
      .then(
        (result) => {
          console.log(result.text);
          setMessage('Message sent successfully');
          resetForm();
        },
        (error) => {
          console.log(error.text);
          setMessage('Message sending failed');
        }
      );
  };

  const resetForm = () => {
    form.current.reset(); // Reset the form
    // Show success message for 5 seconds
    setTimeout(() => {
      setMessage('');
    }, 5000);
  };

  // Use useEffect to clear the message state after 5 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setMessage('');
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [message]);

  return (
    <div>
      {message && <div className="success-message">{message}</div>}
      <div className='col-md-12'>
        <form ref={form} onSubmit={sendEmail} autoComplete='off'>
          <div className='row'  data-aos="fade-up-left">

            <div className='col-md-6'>
              {/* <label>Name</label>
        <input type="text" name="user_name" /> */}
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="user_name"
                  required
                  placeholder="Enter name"

                />
                <label htmlFor="name">Name</label>
              </div>

            </div>

            <div className='col-md-6'>
              {/* <label>Name</label>
        <input type="text" name="user_name" /> */}
              <div className="form-floating mb-3">
                <input
                  type="email"
                  className="form-control"
                  id="name"
                  name="user_email"
                  required
                  placeholder="Enter name"

                />
                <label htmlFor="name">Email Id</label>
              </div>

            </div>

            <div className='col-md-12'>
              {/* <label>Name</label>
        <input type="text" name="user_name" /> */}
              <div className="form-floating mb-3">
                <input
                  type="number"
                  className="form-control"
                  id="name"
                  name="user_mobile"
                  required
                  placeholder="Enter Number"

                />
                <label htmlFor="name">Mobile No.</label>
              </div>

            </div>

            <div className='col-md-12'>
              <div className="form-floating mb-4">
                <textarea
                  className="form-control"
                  name="message"
                  placeholder="Leave a comment here"
                  required
                  id="comments"
                  style={{ height: "200px" }}
                />
                <label htmlFor="comments">Comments</label>
              </div>

            </div>

            <div className="col-12 text-center">
              <button
                className="btn btn-primary border-0 py-2 mb-4"
                style={{ background: 'linear-gradient(to right,#ff0061,#737ee8)' }}
                type="submit"

              >
                Send Message
              </button>
            </div>



          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;



