import React from "react";
import {
  Nav,
  Navbar,
  Container,
  Dropdown,
  DropdownButton,
  Carousel,
} from "react-bootstrap";
import CarouselSlider from "../cmp/Owlcarousel";
import Tesstomany from "../cmp/Ourtesstomany";
import Footer from "../cmp/Footer";
import Whatapp from "../cmp/whatapp";
import Contact from "../cmp/contact";
import ContactHome from "./contact-home";
import { Link } from "react-router-dom";
import "../cmp/ourtes.css";

import Header from "../cmp/header";
const Home = () => {
  return (
    <>
      <Header />

      <div className="container-fluid px-0">
        <Carousel data-bs-theme="dark">
          <Carousel.Item interval={3000}>
            <img
              className="d-block w-100 img-res"
              src="slider1.jpg"
              alt="First slide"
            />

            <Carousel.Caption className="slider-1">
              <p
                className="fs-5 fw-bold res-hide"
                style={{ color: "black" }}
                data-aos="fade-left"
                data-aos-duration="2000"
              >
                Best Loan Services in Karnataka. <br />
                with easy process and assistance
              </p>
              <button className="btn btn-info px-4 fs-5" data-aos="fade-up">
                <a
                  className="text-decoration-none text-white"
                  href="services"
                  target="_blank"
                >
                  Read More
                </a>
              </button>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={3000}>
            <img
              className="d-block w-100 img-res"
              src="slider2.jpg"
              alt="Second slide"
            />
            <Carousel.Caption className="slider-1">
              <h1
                className="display-3 fw-bold"
                style={{ color: "#5918B0" }}
                data-aos="fade-right"
                data-aos-duration="2000"
              >
                Loan for Every Need
              </h1>
              <p
                className="fs-5 fw-bold  res-hide"
                style={{ color: "black" }}
                data-aos="fade-left"
                data-aos-duration="2000"
              >
                Home,personal,business loan etc. <br />
                with easy process and assistance
              </p>
              <button className="btn btn-info px-4 fs-5" data-aos="fade-up">
                <a className="text-decoration-none text-white" href="#">
                  Read More
                </a>
              </button>
            </Carousel.Caption>
          </Carousel.Item>
          {
            <Carousel.Item interval={3000}>
              <img
                className="d-block w-100 img-res"
                src="slider3.jpg"
                alt="Second slide"
              />
              <Carousel.Caption className="slider-1">
                <h1
                  className="display-3 fw-bold"
                  style={{ color: "#5918B0" }}
                  data-aos="fade-right"
                  data-aos-duration="2000"
                >
                  Loan for Every Need
                </h1>
                <p
                  className="fs-5 fw-bold  res-hide"
                  style={{ color: "black" }}
                  data-aos="fade-left"
                  data-aos-duration="2000"
                >
                  Business Loan,Home Loan,foreign Education,Site Loan etc.{" "}
                  <br />
                  with easy process and assistance
                </p>
                <button className="btn btn-info px-4 fs-5" data-aos="fade-up">
                  <a className="text-decoration-none text-white" href="#">
                    Read More
                  </a>
                </button>
              </Carousel.Caption>
            </Carousel.Item>
          }
        </Carousel>
      </div>
      <div className="container py-5">
        <div className="card-box py-3">
          <div className="row">
            <div className="col-lg-3 mb-4" data-aos="fade-right">
              <div
                class="card border-0 shadow"
                style={{ backgroundColor: "#E9652D" }}
              >
                <img
                  src="home_loan.jpg"
                  class="card-img-top"
                  alt="..."
                  style={{ height: "200px" }}
                />
                <div class="card-body  text-white text-center">
                  <h5 class="card-title text-black">Home Loan</h5>
                  <p class="card-text">
                    We offer home loans across India, providing flexible
                    financing solutions to help you achieve your dream of
                    homeownership.. home can be both a sanctuary of comfort and
                    a space for productivity.Striking the right balance is key
                    to creating an environment that supports both relaxation and
                    efficiency.
                  </p>
                  <Link to="/services" class="btn btn-success th-btn">
                    Read More
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mb-4 " data-aos="fade-up">
              <div
                class="card border-0 shadow "
                style={{ backgroundColor: "#E9652D" }}
              >
                <img
                  src="personal_loan.webp"
                  class="card-img-top"
                  alt="..."
                  style={{ height: "200px" }}
                />
                <div class="card-body  text-white text-center">
                  <h5 class="card-title text-black">Personal Loan</h5>
                  <p class="card-text pb-4">
                    A Personal Loan is ideal for funding planned events, such as
                    marriages, vacations & home renovation or unplanned ones,
                    like emergency medical procedures.For More details Visit us
                    or call
                  </p>
                  <Link to="/services" class="btn btn-success mb-5">
                    Read More
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-3 mb-4" data-aos="fade-up">
              <div
                class="card border-0 shadow"
                style={{ backgroundColor: "#E9652D" }}
              >
                <img
                  src="Business-loan.png"
                  class="card-img-top"
                  alt="..."
                  style={{ height: "200px" }}
                />
                <div class="card-body  text-white text-center">
                  <h5 class="card-title text-black">Business Loan</h5>
                  <p class="card-text pb-3">
                    Business loans and working capital are essential for both
                    manufacturers and traders to manage their operations, grow,
                    and scale. Here’s how they help:If you're interested in
                    getting a business loan,Visit us or give a call.
                  </p>
                  <Link to="/services" class="btn btn-success mb-5">
                    Read More
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-3 mb-4" data-aos="fade-left">
              <div
                class="card border-0 shadow"
                style={{ backgroundColor: "#E9652D" }}
              >
                <img
                  src="Education-Loan.jpg"
                  class="card-img-top"
                  alt="..."
                  style={{ height: "200px" }}
                />
                <div class="card-body text-white text-center">
                  <h5 class="card-title text-black">Education Loan</h5>
                  <p class="card-text pb-3">
                    Education Loans for studies both in India and abroad
                    starting from Rs. 10 Lakhs at attractive interest rates.
                    Education Loan you can enjoy a host of benefits such as
                    simple documentation.For More details Visit us or call Us
                  </p>
                  <Link to="services" class="btn btn-success mb-3">
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container py-4 bg-light" id="about-us">
        <h3 className="text-center mb-5">
          <span
            style={{ borderBottom: "3px solid #E62E5E", paddingBottom: "10px" }}
          >
            About Our Compnany{" "}
          </span>
        </h3>

        <div className="row">
          <div className="col-md-6">
            <div className="px-4">
              <p className="fs-3" style={{ textAlign: "justify" }}>
                If you are looking for the best loan services in Bengaluru, then
                you owe it to yourself to get in touch with{" "}
                <span className="fw-bold fs-2">KK Financial Services.</span>{" "}
                When a sum of money is required, say for a home or automobile or
                Business, most people will turn to loan companies to get the
                financial support required. Once your loan is approved, we
                provide the money from Banks/NBFCS in a lump sum and let you
                repay over the time you need to avoid the stress loans can
                cause. Pop in and see us or give us a ring on{" "}
                <span className="fw-bold fs-3">+91 8494941209 </span>to find out
                more.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <img
                src="owner.jpeg"
                style={{ width: "100%" }}
                className="mb-2"
                data-aos="fade-up-left"
              />
              <h3>Co-Founder : Rajendra Prasad</h3>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid py-4">
        <div className="row">
          <div className="col-md-6">
            <div>
              <img
                src="whychoose-us.jpg"
                style={{ width: "100%" }}
                data-aos="fade-up-right"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="px-4">
              <h3 className="mb-5">
                <span
                  style={{
                    borderBottom: "3px solid #E62E5E",
                    paddingBottom: "10px",
                  }}
                >
                  Why choose us{" "}
                </span>
              </h3>
              <div>
                <div className="d-flex align-items-center">
                  <img src="innovate.png" style={{ width: "120px" }} />
                  <div>
                    <h4>Innovative Works</h4>
                    <p className="fs-5">
                      Clear understaing of indiviudal fiancial needs and
                      providng fund solution .
                    </p>
                  </div>
                </div>

                {/* <div className='d-flex mb-4'>
                                    <img src='certified.png' style={{ width: '100px', marginRight: '25px' }} />
                                    <div>
                                        <h4>

                                            Certified Company
                                        </h4>
                                        <p>
                                            There anyone who loves or pursues or desires to obtain pain of itself and expound teachings
                                        </p>
                                    </div>
                                </div> */}

                <div className="d-flex mb-3 align-items-center">
                  <img src="exp.png" style={{ width: "120px" }} />
                  <div>
                    <h4>Experienced Team</h4>
                    <p className="fs-5">
                      Experienced skilled team having 3 decade experience in
                      lending banking and financial services.
                    </p>
                  </div>
                </div>

                <div className="d-flex mb-3 align-items-center">
                  <img src="exp.png" style={{ width: "120px" }} />
                  <div>
                    <h4>Comprehensive Loan Options:</h4>
                    <p className="fs-5">
                      Whether you're buying a home, a vehicle, or need funding
                      for your business, they offer tailored solutions to suit
                      your financial requirements.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container-fluid py-5 pb-0"
        style={{ backgroundColor: "#31343C" }}
      >
        <h3 className="text-center mb-2"></h3>
        <div>
          <ContactHome />
        </div>
      </div>

      <div>
        <Whatapp />
        <Footer />
      </div>
    </>
  );
};

export default Home;
