import React from "react";
import Emailid from "./email";
const contactHome = () => {
  return (
    <>
      <div className="container-fluid py-5 bg-light">
        <div className="container">
          <h3 className="text-center mb-4">
            <span
              style={{
                borderBottom: "3px solid #E62E5E",
                paddingBottom: "10px",
              }}
            >
              Contact With Us{" "}
            </span>
          </h3>
          <p className="text-center fs-5 mb-4">
            Don’t hestiate to ask us something, Our customer support team always
            ready to help you,
            <br />
            they will support you 24/7.
          </p>
          <div className="row">
            <div className="col-md-6 " data-aos="fade-up-right">
              <img src="11.jpg" className="mb-3" style={{ width: "100%" }} />

              <div className="row">
                <div className="col-md-6">
                  <div className="d-flex">
                    <div className="me-4">
                      <i
                        class="fa fa-map-marker fs-2"
                        style={{ color: "#E62E5E" }}
                      ></i>
                    </div>
                    <div>
                      <h3>Address</h3>
                      <p className="fs-5">
                        #384,3rd Main Road, 13th Cross Rd, Sadashiva Nagar,
                        Bengaluru, Karnataka 560080
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-flex ">
                    <div className="me-4">
                      <i
                        class="fa fa-envelope fs-2"
                        style={{ color: "#E62E5E" }}
                      ></i>
                    </div>
                    <div>
                      <h3>Email Id</h3>
                      <p className="fs-5">info@kkfinancialserve.in</p>
                      <p className="fs-5">
                        +91 84949 41209, <br /> +91 83105 15345
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className='col-md-6'>
                            <form>
                            <div className='row'>
                                <div className='col-6'>
                                    <div class="form-floating mb-3">
                                        <input type="text" class="form-control" id="floatingInput" required placeholder="Enter name" />
                                        <label for="floatingInput">Name</label>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div class="form-floating">
                                        <input type="email" class="form-control" id="floatingPassword" required placeholder="Email id" />
                                        <label for="floatingPassword">Email Id</label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-floating mb-3">
                                <input type="number" class="form-control" id="floatingPassword" required placeholder="Mobile No" />
                                <label for="floatingPassword">Mobile No.</label>
                            </div>

                            <div class="form-floating mb-4">
                                <textarea class="form-control" placeholder="Leave a comment here" required id="floatingTextarea2" style={{ height: "200px" }}></textarea>
                                <label for="floatingTextarea2">Comments</label>
                            </div>
                            <div class="col-12 text-center">
                                <button class="btn btn-primary border-0 py-2" style={{background:'linear-gradient(to right,#ff0061,#737ee8)'}} type="submit">Send Message</button>
                            </div>
                            </form>




                        </div>  */}
            <div className="col-md-6">
              <Emailid />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default contactHome;
